import React, { Component } from "react";
import "./ConfirmationModal.scss";

export default class ConfirmationModal extends Component {
  render() {
    return (
      <div
        className={`confirmation-modal-page ${this.props.hidden && "hidden"}`}
      >
        <div className="confirmation-modal-window">
          {this.props.loginResponse && (
            <h4>{`Confirm that you would like to donate $${this.props.loginResponse.Donation} to the following:`}</h4>
          )}
          {this.props.content && (
            <div className="confirmation-modal-window-content">
              <div className="flex-row">
                <div className="row-name">School:</div>
                <div className="row-content">
                  {this.props.content.CauseName}
                </div>
              </div>
              <div className="flex-row">
                <div className="row-name">Location:</div>
                <div className="row-content">
                  {this.props.content.CauseLocation}
                </div>
              </div>
            </div>
          )}

          <div className="button-section-red">
            <button onClick={this.props.closeModal} className="button btn">
              Cancel
            </button>
            <button
              onClick={this.props.handleDonationSubmit}
              className="button btn"
              name="final-submit-button"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}
