import React, { Component } from "react";
import Favicon from "react-favicon";
import queryString from "query-string";
import App from "../App";
import DiscontinuedPage from "../DiscontinuedPage";
import { ForbiddenScreen } from "../ErrorScreen";

class DataWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDiscontinued: false,

      selectedAccount: "mysegcause",

      CDDPresent: false,
      CDDUI: "",

      loading: true,

      forbidden: false,

      sessionInfo: {

        myhannafordcause: {
          account: "hf",
          gaID: "UA-142611659-1",
          bannersURL: "",
          statesURL: "HCStates",
          storesURL: "HCStores",
          accountUID: "779F5862-7EEB-4229-BA69-9BF7E42FE249",
          programUID: "4D5F857B-6101-45EB-8AEA-1C9DEF59DFF5",
          causeView: '[dbo].[PSIMVW_CDDSHCcause]',
          addressTemplate: "${address}, ${city}",
          variableFields: {
            dateField: false,
            banners: false,
            states: false,
            stores: false,
          },
          removeSubmission: false,
        },
      
      },
      pageContent: {
        myhannafordcause: {
          title: "Hannaford Helps Schools Program",
          subTitle: "Hannaford Helps Schools Program",
          style: {
            primaryColor: "#da291c",
            secondaryColor: "#da291c",
            tertiaryColor: "",
            font: "",
          },
          contactInfo: {
            tagRedemptionURL: "https://www.hannafordhelpsschools.com/",
            subsiteURL: "https://www.hannafordhelpsschools.com/",
            programManager: "Jim Brennan",
            phone: "(603) 380-9353",
            email: "info@hannafordhelpsschools.com",
          },
          privacy: (
            <p>
              All information provided will strictly adhere to Hannaford's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hannaford.com/customer-service/privacy"
              >
                Privacy Policy
              </a>
            </p>
          ),
          bottomStatement:
            "Hannaford Supermarkets has a long history of supporting education and specifically local schools in our communities. The Hannaford Helps Schools program began in 1999 and has since raised more than $17 million for local schools in New England and New York.",
          criteria: (
            <div className="requirements">
              <h3 style={{ color: "#da291c" }}>
                Non-Profit Criteria for the Program
              </h3>
              <p>
                The purpose of this program is to support the non-profits in
                your community that are important to you. To be considered for a
                donation, organizations must have:
              </p>
              <ul>
                <li>501(c)(3) status.</li>
                <li>
                  A direct local connection to a specific Hannaford store.
                </li>
                <li>
                  Non-profits that are clearly focused in their stated mission
                  to impact hunger relief, education (all aspects), health &
                  wellness, civic, or military/veterans organizations
                </li>
                <li>
                  Organizations that provide for the physical, emotional,
                  educational and cultural enhancement and well-being of
                  children
                </li>
              </ul>
              <p>
                Requests That Will <b>Not</b> Qualify:
              </p>
              <ul>
                <li>
                  Events/Fundraisers limited to specific individuals or families
                </li>
                <li>International Organizations</li>
                <li>
                  Any organization that does not put 100% of the donations to
                  supporting the local community
                </li>
                <li>
                  Requests to support expenses related to travel or conferences
                  for employees of non-profit organizations
                </li>
                <li>
                  Requests to support expenses related to fundraisers/events.
                </li>
                <li>Private clubs, such as rotary clubs</li>
                <li>
                  Any organization that discriminates on the basis of gender,
                  race, ethnicity, religion and/or sexual orientation
                </li>
              </ul>
            </div>
          ),
          tagDetails: {
            length: 6,
            mask: [
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
              /[A-Za-z0-9]/,
            ],
            placeholder: "______",
          },
        },
         },
    };
  }

  componentDidMount() {
    const { sessionInfo } = this.state;

    const hostnameArr = window.location.hostname.split(".");

    const params = queryString.parse(window.location.search);

    if (window.location.hostname.includes("mytestcause")) {
      if (!(params.code && params.code === "68310824")) {
        this.setState({ forbidden: true });
        return;
      }
    }

    if ("CDDUI" in params) {
      this.setState({
        CDDUI: params.CDDUI,
        CDDPresent: true,
      });
    }

    const subdomain = "myhannafordcause"

    const accountList = Object.keys(sessionInfo);

    let account = "";

    if (hostnameArr[0]?.toLowerCase() === "bookwellfeeds") {
      account = "bookwellfeeds";
    } else if (accountList.includes(subdomain)) {
      account = subdomain;
    } else if ("test" in params) {
      accountList.forEach((acc) => {
        console.log(acc);
        console.log(sessionInfo[acc].account);
        if (sessionInfo[acc].account === params.test.toLowerCase()) {
          account = acc;
        }
      });
    } else {
      console.log("forbidden because of subdomain", subdomain);
      this.setState({ forbidden: true });
      return;
    }

    const discontinuedAccounts = ["mygmcause"];

    if (discontinuedAccounts.includes(account)) {
      this.setState({ isDiscontinued: true });
    }

    console.log(account);

    this.setState((prevState) => ({
      loading: false,
      selectedAccount: account,
      faviconURL: `${process.env.PUBLIC_URL}images/${prevState.sessionInfo[account].account}/favicons/favicon.ico`,
    }));
  }

  cancelCDD = () => {
    this.setState({
      CDDUI: "",
      CDDPresent: false,
    });
  };

  render() {
    const {
      forbidden,
      selectedAccount,
      loading,
      faviconURL,
      CDDUI,
      CDDPresent,
      sessionInfo,
      pageContent,
    } = this.state;

    if (forbidden) {
      return <ForbiddenScreen />;
    }

    if (this.state.isDiscontinued) {
      return (
        <div>
          <Favicon url={faviconURL} />
          <DiscontinuedPage
            CDDUI={CDDUI}
            CDDPresent={CDDPresent}
            selectedAccount={selectedAccount}
            sessionInfo={sessionInfo[selectedAccount]}
            pageContent={pageContent[selectedAccount]}
            cancelCDD={this.cancelCDD}
          />
        </div>
      );
    }

    if (selectedAccount && !loading) {
      return (
        <div>
          <Favicon url={faviconURL} />
          <App
            CDDUI={CDDUI}
            CDDPresent={CDDPresent}
            selectedAccount={selectedAccount}
            sessionInfo={sessionInfo[selectedAccount]}
            pageContent={pageContent[selectedAccount]}
            cancelCDD={this.cancelCDD}
          />
        </div>
      );
    }
    return <div />;
  }
}

export default DataWrapper;
