import React from "react";
import "./ThankYou.scss";


const ThankYou = ({ isNewCause, selectedCause, loginResponse, donationAmount }) => (
  <div>
  <div className="thank-you-page">
        <div className="confirmation-modal-window">
          <h1 style={{backgroundColor: "#da291c", color: "white"}}>Submission Received!</h1>
             <img
                  className="bag-image"
                  src={`/images/hf/logo.png`}
                  alt=""
                />
          <div className="confirmation-modal-window-content">
              <div className="flex-row">
              <div className="row-content-large">Thank you for your donation of <b>${loginResponse.Donation}</b> to <b>{loginResponse.SelectedCause?.CauseName}</b></div>
              </div>
              <div className="flex-row">
              <div className="row-content-small">
              "Hannaford Supermarkets has a long history of supporting education and specifically local schools in our communities. The Hannaford Helps Schools program began in 1999 and has since raised more than $17 million for local schools in New England and New York."
              </div>
              </div>
            <div className="button-section-red">
              <a href="https://www.hannafordhelpsschools.com/" className="redButton btn" name="final-submit-button">OK</a>
            </div>
          </div>
          </div>
          </div>
          </div>
);

export default ThankYou;
