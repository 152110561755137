import React, { Component } from "react";
import API from "../API";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Selection.scss";

import queryString from "query-string";

export default class Selection extends Component {
  constructor() {
    super();

    this.state = {
      formOpen: false,
      categories: [],
      criteria: <div />,
    };

    this.fetchData = this.fetchData.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  componentDidMount() {
    // this.getCauseCategories();

    if (this.props.sessionInfo.account === "gm") {
      this.setState({
        criteria: this.props.pageContent.criteria[this.props.selectedChain],
      });
    } else {
      this.setState({
        criteria: this.props.pageContent.criteria,
      });
    }
  }

  toggleForm() {
    this.setState({
      formOpen: !this.state.formOpen,
    });
  }

  fetchData(state) {
    this.props.updateTable(state.pageSize, state.page, state.sorted);
  }

  // getCauseCategories() {
  //   API.get("/Cause/GetCauseCategories", {
  //     params: {
  //       accountUID: this.props.sessionInfo.accountUID,
  //       companyUID: this.props.loginResponse.PurchaseLocation,
  //     },
  //   })
  //     .then((res) => {
  //       const newState = {
  //         categories: res.data.Records.map((record) => {
  //           return {
  //             value: record.CategoryID,
  //             label: record.Category,
  //           };
  //         }).filter((category) => category.value !== 0),
  //       };
  //       const params = queryString.parse(window.location.search);
  //       if ("mode" in params && params.mode.toLowerCase() === "test") {
  //         this.props.updateSelectedCategory(newState.categories[0]);
  //       }
  //       this.setState(newState);
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         errorHasOccured: true,
  //       });
  //     });
  // }

  render() {
    let columns = [];
    if (this.props.screenSize === "large") {
      columns = [
        {
          Header: "School",
          id: "CauseName",
          accessor: "CauseName",
        },
        {
          Header: "City",
          accessor: "CauseCity",
          maxWidth: 180,
        },
        {
          Header: "State",
          accessor: "CauseState",
          maxWidth: 60,
        },
        {
          Header: "Zipcode",
          accessor: "CauseZip",
          maxWidth: 150,
          Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
        },
      ];
    } else if (this.props.screenSize === "medium") {
      columns = [
        {
          Header: "School",
          id: "CauseName",
          accessor: "CauseName",
        },
        {
          Header: "Location",
          accessor: "CauseLocation",
        },
      ];
    } else {
      columns = [
        {
          Header: "School",
          id: "CauseName",
          accessor: (d) => [d.CauseName, d.CauseCity, d.CauseState],
          Cell: (row) => (
            <div>
              <div>{row.value[0]}</div>
              <div>{`${row.value[1]} - ${row.value[2]}`}</div>
            </div>
          ),
        },
      ];
    }

    console.log("props in selection", this.props);

    const searchNew = async (e) => {
      console.log("no data", this.props.data.length);

      if (this.props.data.length < 1) {
        console.log("no data", this.props.data.length);
        this.setState({ hidden: false });
      }
      this.props.onSearchChange(e);
    };
    // if (this.props.onSearchChange) {
    //   if (this.props.data.length < 1) {
    //     setTimeout(() => {
    //       this.setState({ hidden: false });
    //     }, 3500);
    //     console.log("no data");
    //   }
    // }

    return (
      <div className="selection-page">
        <h3>
          Search for your school by typing your school's <b>name</b>,{" "}
          <b>city</b>, or <b>zipcode</b>.
        </h3>
        <h4>
          {" "}
          Once you've located the school, you can verify your donation amount
          and submit your School Dollars donation by clicking on the school and
          then hitting submit.
        </h4>
        <div className="top-section">
          <section className="title-section"></section>
        </div>
        <div
          className="table-section"
          style={{ backgroundColor: this.props.pageContent.style.primaryColor }}
        >
          <div className="table-controls">
            <div className="form-group">
              <label htmlFor="causeSearch">Search Schools</label>
              <input
                type="text"
                className="form-control"
                id="causeSearch"
                aria-describedby="causeSearch"
                placeholder="Enter School Name, City or Zipcode to Find your School"
                value={this.props.causeSearchTerm}
                onChange={this.props.onSearchChange}
              />
            </div>
          </div>
          <ReactTable
            columns={columns}
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={this.props.data}
            showPaginationTop
            showPaginationBottom={false}
            pages={this.props.numPages} // Display the total number of pages
            loading={this.props.loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            pageSize={this.props.pageSize}
            showPageSizeOptions={false}
            showPageJump={false}
            className="-striped -highlight"
            resizable={false}
            page={this.props.pageNum}
            onPageChange={this.props.handlePageChange}
            getTrProps={this.props.handleCauseClick}
            noDataText={`No schools found.`}
          />
          <div
            className={`modal-page ${
              this.props.selectionNoDataModal && "hidden"
            }`}
          >
            <div className="modal-window">
              <div className="modal-window-content">
                <h2 className="confirmation-modal-title">
                  Don't see your school?
                </h2>
                <h3 className="confirmation-modal-header">
                  Please submit your school for consideration in the 2023
                  program to{" "}
                  <a
                    href={"mailto:hannafordhelpsschools@hannaford.com"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="email-link"
                  >
                    hannafordhelpsschools@hannaford.com
                  </a>
                  .
                </h3>
              </div>
              <div className="button-section">
                <button
                  onClick={this.props.closeSelectionNoDataModal}
                  className="btn btn-dark"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
