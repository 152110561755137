import React from "react";
import EnvelopeIcon from "../Icons/EnvelopeIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import "./Footer.scss";

const Footer = ({ pageContent }) => (
  <footer style={{ backgroundColor: pageContent.style.primaryColor }}>
    <div className="header">
      <div className="header-text">
        If you have any questions, please contact us at:
      </div>
    </div>
    <div className="content">
      <div className="contact-info">
        <div className="info-row">
          <PhoneIcon />
          <span className="info-text">{pageContent.contactInfo.phone}</span>
        </div>
        <div className="info-row">
          <EnvelopeIcon />
          <span className="info-text">
            <a href={`mailto:${pageContent.contactInfo.email}`}>
              {pageContent.contactInfo.email}
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
