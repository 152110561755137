import { polyfill } from "es6-promise";

polyfill();

const axios = require("axios").default

const baseURL = window.location.hostname.toLowerCase().includes("localhost")
  ? "https://redemption.azurewebsites.net/api"
  : "https://redemption.azurewebsites.net/api";
  const instance = axios.create({
    baseURL: baseURL
  });
  
export default instance