import React, { Component } from "react";
import "./Modal.scss";

export default class Modal extends Component {
  render() {
    return (
      <div className={`modal-page ${this.props.hidden && "hidden"}`}>
        <div className="modal-window">
          <div className="modal-window-content">
            {this.props.content.map((el, i) => {
              return <div key={el + i}>{el}</div>;
            })}
          </div>

          <div className="button-section">
            <button onClick={this.props.closeModal} className="btn btn-dark">
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
}
