import React, { Component } from "react";
import "./Home.scss";
import MaskedInput from "react-text-mask";
import "react-day-picker/lib/style.css";

import TagField from "../TagField";

export default class Home extends Component {
  constructor(props) {
    super(props);
    const earliest = new Date();
    earliest.setDate(earliest.getDate() - 7);
    const latest = new Date();

    this.state = {
      disabledDays: [
        {
          after: latest,
          before: earliest,
        },
      ],
    };
  }


  render() {
    return (
      <div id="home-page">
        <div className="body-content">
          <div className="top-content">
            <div className="image-container">
              <a
                rel="noopener noreferrer"
                href={this.props.pageContent.contactInfo.subsiteURL}
                target="_blank"
              >
                <img
                  className="bag-image"
                  src={`${process.env.PUBLIC_URL}/images/${this.props.sessionInfo.account}/logo.png`}
                  alt=""
                />
              </a>
            </div>
            <div className="intro-paragraphs">
              <img src="/images/Number_1.png" />
              <h1 style={{ color: "#da291c" }}>
                Enter Your Coupon Code.</h1>
              <h4 style={{ color: "#da291c" }}>Enter the 6-letter coupon code and the store number in the spaces below. <b>There are no numbers in the coupon code.</b></h4>
              <p>(circled on the sample below)</p>
            </div>
          </div>

          <form>

            <TagField
              pageContent={this.props.pageContent}
              tags={this.props.tags}
              addTag={this.props.addTag}
              removeTag={this.props.removeTag}
              openToast={this.props.openToast}
              tagInput={this.props.tagInput}
              handleTagInputChange={this.props.handleTagInputChange}
              handleKeyDown={this.props.handleKeyDown}
              tagDetails={this.props.pageContent.tagDetails}
              sessionInfo={this.props.sessionInfo}
            />
            <div id="store-num-field">
              <div className="store-num-input-container">
                <div className="container-flex-row">
                  <h5>STORE #: &nbsp; </h5> &nbsp;
                  <MaskedInput
                    mask={[  /[A-Za-z0-9]/,
                    /[A-Za-z0-9]/,
                    /[A-Za-z0-9]/,
                    /[A-Za-z0-9]/]}
                    className="store-num-input"
                    placeholder={"____"}
                    guide={true}
                    value={this.props.storeNumber}
                    onChange={this.props.handleStoreNumberInputChange}
                    onKeyDown={this.props.handleKeyDown}
                  /></div>
              </div>
            </div>
            <img src="/images/coupon.png" className="coupon" />

          </form>
<br />
          <img src="/images/Number_2.png" />
          <div className="button-container">
            <h1 style={{ color: "#da291c" }}>Support Your School</h1>
            <button
              onClick={this.props.handleSubmitClick}
              className={`btn btn-lg btn-${this.props.sessionInfo.account}`}
              name="submit-button"
            >
              Click Here to Select Your School
            </button>
          </div>

          <div
            className="privacy"
            style={{ color: this.props.pageContent.style.primaryColor }}
          >
            {this.props.pageContent.privacy}
          </div>

          <p className="bottom-statement">
            {this.props.pageContent.bottomStatement}
          </p>
        </div>
      </div>
    );
  }
}
